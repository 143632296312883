.modal-overlay {
  /* Ensures the overlay covers the entire screen */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* Styles for the modal content */
  background-color: #000;
  color: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  
  /* Hide scrollbar for Chrome, Safari, and Opera */
  scrollbar-width: none; /* Firefox */
}

.modal-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}